<template>
  <div :class="currentPath !== '/' && currentPath !== '/home' ? 'footer' : 'footer-home'">
    <div class="new-push" v-if="currentPath === '/' || currentPath === '/home'">
      <div class="title">{{ $t('navbar.latest') }}</div>
      <el-divider class="divider"></el-divider>
      <div class="center-content">
        <div class="content" @click="handleDetails">
          <span style="margin-right: 18px">{{ updateTime }}</span> <span>{{ pushName }}</span>
        </div>
      </div>

      <el-divider class="divider"></el-divider>
    </div>
    <div>
      <!--    旅游公司-->
      <div class="center">
        <div
          :class="
            currentPath !== '/' && currentPath !== '/home' ? 'footer-title' : 'footer-title-home'
          "
        >
          <span>{{ $t('navbar.tourismCompanyName') }}</span>
        </div>
        <div class="contact contcat-top">
          <div class="content">
            <div class="label">{{ $t('navbar.address') }}</div>
            <div class="value" :class="lang === 'en' ? 'inter' : 'irohamaru'">{{$t('footer.tourismCompanyAddress')}}</div>
          </div>
          <div class="content">
            <div class="label">TEL</div>
            <div class="value" :class="lang === 'en' ? 'inter' : 'irohamaru'">{{$t('footer.tourismCompanyTel')}}</div>
          </div>
        </div>
        <div class="contact">
          <div class="content">
            <div class="label">E-mail</div>
            <div class="value" :class="lang === 'en' ? 'inter' : 'irohamaru'">{{$t('footer.tourismCompanyEmail')}}</div>
          </div>
          <div class="content">
            <div class="label">FAX</div>
            <div class="value" :class="lang === 'en' ? 'inter' : 'irohamaru'">{{$t('footer.tourismCompanyFax')}}</div>
          </div>
        </div>
      </div>
      <!--    专车公司-->
      <div class="center">
        <div class="footer-title-home-company">
          <span>{{ $t('navbar.companyName') }}</span>
        </div>
        <div class="contact contcat-top">
          <div class="content">
            <div class="label">{{ $t('navbar.address') }}</div>
            <div class="value" :class="lang === 'en' ? 'inter' : 'irohamaru'">{{$t('footer.address')}}</div>
          </div>
          <div class="content">
            <div class="label">TEL</div>
            <div class="value" :class="lang === 'en' ? 'inter' : 'irohamaru'">{{$t('footer.tel')}}</div>
          </div>
        </div>
        <div class="contact">
          <div class="content">
            <div class="label">E-mail</div>
            <div class="value" :class="lang === 'en' ? 'inter' : 'irohamaru'">{{$t('footer.email')}}</div>
          </div>
          <div class="content">
            <div class="label">FAX</div>
            <div class="value" :class="lang === 'en' ? 'inter' : 'irohamaru'">{{$t('footer.fax')}}</div>
          </div>
        </div>
      </div>
      <el-divider class="content-divider"></el-divider>
      <div class="footer-content">
        <div class="content-top">
          <div @click="toPage('/')">{{ $t('navbar.home') }}</div>
          <div @click="toPage('/car-introduction')">{{ $t('navbar.carIntroduction') }}</div>
          <div @click="toPage('/car-service')">{{ $t('navbar.carService') }}</div>
          <div @click="toPage('/add-service')">{{ $t('navbar.addService') }}</div>
          <div @click="toPage('/clubs-summary')">{{ $t('navbar.clubsSummary') }}</div>
          <div @click="toPage('/contact-us')">{{ $t('navbar.contactUs') }}</div>
        </div>
        <div class="content-center-one">
          <div @click="toPage('/car-introduction?type=2')">{{ $t('navbar.charteredCar') }}</div>
          <div @click="toPage('/car-service?type=2')">{{ $t('navbar.serviceConfiguration') }}</div>
          <div @click="toPage('/add-service')">{{ $t('navbar.exclusiveService') }}</div>
          <div @click="toPage('/clubs-summary?type=1')">{{ $t('navbar.companyPhilosophy') }}</div>
          <div @click="toPage('/contact-us?type=1')">{{ $t('navbar.onlineMessage') }}</div>
        </div>
        <div class="content-center-two">
          <div @click="toPage('/car-service?type=3')">{{ $t('navbar.serviceAdvantage') }}</div>
          <div></div>
          <div @click="toPage('/clubs-summary?type=2')">{{ $t('navbar.clubsSummary') }}</div>
          <div @click="toPage('/contact-us?type=2')">{{ $t('navbar.joinUs') }}</div>
        </div>
        <div class="content-down">
          <div @click="toPage('/clubs-summary?type=4')">{{ $t('navbar.serviceContent') }}</div>
          <div @click="toPage('/contact-us?type=3')">{{ $t('navbar.commonQA') }}</div>
        </div>
      </div>
    </div>

    <div class="footer-text inter">
      Copyright © 2024 DD Tourism Co. Ltd · DD Tokyo All rights reserved
    </div>
  </div>
</template>

<script>
import { getDynamic } from '@/api/push'

export default {
  name: 'Footer',
  data() {
    return {
      path: '',
      lang: '',
      pushName: '',
      updateTime: ''
    }
  },
  computed: {
    currentPath() {
      return this.$route.path
    }
  },
  watch: {
    '$i18n.locale'(newValue) {
      this.lang = newValue
      console.log(this.lang)
      console.log(1222)
      this.getDynamic(false)
    }
  },
  created() {
    // {{ $t('home.aboutUs.title') }}

    this.lang = localStorage.getItem('language') || 'jap'
    console.log(this.lang)
    console.log(22333)
  },
  mounted() {
    this.path = this.$route.path
    this.getDynamic(true)

    console.log(this.$route)
  },
  methods: {
    getDynamic() {
      getDynamic(this.lang).then(res => {
        this.pushName = res.data[0].title
        this.updateTime = this.extractYMD(res.data[0].updateTime)
      })
    },
    extractYMD(dateTimeStr) {
      // 使用split方法按照"-"和" "进行分割
      // 首先分割出年月日部分（"YYYY-MM-DD"），然后再忽略时间和剩余的部分
      const parts = dateTimeStr.split(' ')[0].split('-')

      // 确保我们得到了正确的部分数量
      if (parts.length === 3) {
        // 返回组合后的年月日字符串
        return parts[0] + '-' + parts[1] + '-' + parts[2]
      } else {
        // 如果格式不正确，返回空字符串或错误消息
        return ''
      }
    },
    handleDetails() {
      this.$router.push('/push-details')
    },
    toPage(e) {
      this.$router.push(e)
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  background-color: $bg-color;
  color: $light-color;
  width: 100%;
  height: 1135px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 0 330px;
  white-space: pre-wrap;
}
.footer-home {
  background-color: $bg-color;
  color: $light-color;
  width: 100%;
  height: 1407px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 0 330px;
  white-space: pre-wrap;
}
.new-push {
  padding-top: 179px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .title {
    font-weight: bold;
    font-size: 40px;
    line-height: 42px;
    letter-spacing: 0.3px;
    margin-bottom: 6px;
  }
  .divider {
    margin: 30px 0;
  }
  .center-content {
    width: 1200px;
    display: flex;
    align-items: center;
    justify-content: center;
    .content {
      cursor: pointer;
      font-size: 20px;
      line-height: 35px;
      letter-spacing: 0.2px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
.footer-title {
  font-size: 48px;
  line-height: 52px;
  color: #86868b;
  margin-top: 130px;
  margin-bottom: 40px;
  font-weight: 350;
}
.footer-title-home {
  font-size: 48px;
  line-height: 52px;
  color: #86868b;
  margin-top: 20px;
  margin-bottom: 40px;
  font-weight: 350;
}
.footer-title-home-company {
  font-size: 48px;
  line-height: 52px;
  color: #86868b;
  margin-top: 64px;
  margin-bottom: 40px;
  font-weight: 350;
}
.center {
  padding-left: 52px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .contcat-top {
    margin-bottom: 49px;
  }
  .contact {
    display: flex;
    align-items: center;
    .content {
      width: 588px;
      height: 61px;
      box-sizing: border-box;

      .label {
        font-size: 16px;
        margin-bottom: 20px;
        color: rgba(255, 255, 255, 0.92);
        font-weight: 350;
        line-height: normal;
      }
      .value {
        font-size: 16px;
        line-height: 18px;
        color: rgba(255, 255, 255, 0.8);
        font-weight: 400;
      }
    }
  }
}

.content-divider {
  margin-top: 64px !important;
  margin-bottom: 45px !important;
}
.footer-content {
  padding-left: 52px;
  padding-right: 164px;
  margin-bottom: 64px;
  .content-top {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    div {
      width: 176px;
      margin-right: 20px;
      font-weight: bold;
      font-size: 16px;
      line-height: 23px;
      cursor: pointer;
    }
  }
  .content-center-one {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
    margin-left: 196px;
    div {
      width: 176px;
      margin-right: 20px;
      font-size: 16px;
      line-height: 18px;
      color: rgba(255, 255, 255, 0.8);
      cursor: pointer;
    }
  }
  .content-center-two {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
    margin-left: 392px;
    div {
      width: 176px;
      margin-right: 20px;
      font-size: 16px;
      line-height: 18px;
      color: rgba(255, 255, 255, 0.8);
      cursor: pointer;
    }
  }
  .content-down {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 784px;

    div {
      width: 176px;
      margin-right: 20px;
      font-size: 16px;
      line-height: 18px;
      color: rgba(255, 255, 255, 0.8);
      cursor: pointer;
    }
  }
}
.footer-text {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.56);
}

::v-deep .el-divider--horizontal {
  margin: 0px 0;
}
::v-deep .el-divider {
  background-color: rgba(255, 255, 255, 0.24);
}
</style>
