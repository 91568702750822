// 中文（繁体）
export default {
  home: {
    title: '首页',
    slogan: '安全第一\n' + '服務至上\n' + '以客為尊\n' + '賓至如歸',
    useCar: '用車',
    aboutUs: {
      title: '關於我們',
      content1: '以“服務至上，賓至如歸“爲公司理念，爲您提供專業、高端、安全、細致入微的服務體驗。',
      content2:
        '“株式會社DDハイヤー”是位于東京的擁有十年以上行業經驗的正規專車公司。多種車型應對不同用車需求；車輛皆爲高端配置，定期檢修，保證您的用車安全；司機及工作人員嚴格培訓上崗，服務讓您安心、貼心、放心。',
      content3:
        '“株式會社DDハイヤー”爲日本的專業國際化專車公司，公司有多國從業人員，提供日語、英語、中文、韓語等語言對應，海外客人也可安心享受。',
      content4:
        '提供空港接送、商務用車、觀光用車、高爾夫接送等用車或包車服務，同時還有株式會社DDツーリズム爲後盾，爲您提供專業日本遊覽等服務。'
    }
  },
  navbar: {
    home: '首頁',
    carIntroduction: '車種介紹',
    carService: '用車服務',
    addService: '旅行服務',
    clubsSummary: '公司概要',
    contactUs: '聯係我們',
    language: '語言切換',
    charteredCar: '日本全境包車',
    serviceConfiguration: '服務配置',
    serviceAdvantage: '服務優勢',
    exclusiveService: '專享服務',
    companyPhilosophy: '公司理念',
    companyProfile: '公司概要',
    serviceContent: '服務內容',
    onlineMessage: '在線留言',
    joinUs: '加入我們',
    commonQA: '常見Q&A',
    latest: '最新推送',
    companyName: 'DD專车有限公司',
    tourismCompanyName: 'DD旅遊有限公司',
    address: '公司地址'
  },
  routeTitle: {
    home: '首頁',
    carIntroduction: '車種介紹',
    carService: '用車服務',
    addService: '旅行服務',
    clubsSummary: '公司概要',
    contactUs: '聯係我們',
    pushDetails: '推送詳情'
  },
  common: {
    language: '语言',
    submit: '提交',
    order: '立即預約',
    onlineMsg: {
      process: '服務流程',
      message: '在線留言',
      step1:
        '填寫表單，輸入基礎資訊和聯繫方式，提交後，客服會第一時間聯繫您。您也可以通過電話直接與我們聯繫哦。',
      step2: '客服根據您的需求，為您定制服務後報價，確認好服務內容和價格後，支付服務費用。',
      step3: '全程無憂服務，司機主動與您聯繫，給予貼心陪伴的專車專享高端旅程。'
    },
    successMsg: {
      title: '提交成功'
    },
    formMsg: {
      baseInfo: '基本信息',
      rlus: '請輸入',
      rlusSelect: '請選擇',
      name: {
        label: '姓名',
        placeholder: '請輸入您的姓名，例)山田 太郎'
      },
      email: {
        label: '電子郵件',
        placeholder: '請輸入郵箱地址，例)xxxabytedance.',
        rlus: '請輸入正確郵箱地址'
      },
      tel: {
        label: '聯繫電話',
        placeholder: '支持海外號碼，例)120666131',
        rlus: '請輸入正確電話號碼'
      },
      demand: '用車需求',
      address: {
        label: '出發地',
        placeholder: '其他地點請輸入',
        option: ['成田空港', '羽田空港', '其他空港']
      },
      expectedDate: {
        label: '期望日期',
        placeholder: '請選擇日期，年/月/日'
      },
      flight: {
        label: '預訂航班',
        placeholder: '例)NH104'
      },
      destination: {
        label: '目的地',
        placeholder: '例)東京都千代田區'
      },
      peopleNumber: {
        label: '人數',
        placeholder: '請填寫乘車人數'
      },
      luggageNumber: {
        label: '行李數',
        placeholder: '請填寫行李數'
      },
      contactInformation: {
        label: '首選聯繫方式',
        placeholder: ''
      },
      pickUpService: {
        label: '接機服務',
        placeholder: '接機服務是指司機持名牌在機場大門出口等候的服務'
      },
      childSeat: {
        label: '兒童座椅',
        placeholder: ''
      },
      language: {
        label: '英語/韓語',
        placeholder: ''
      },
      remark: {
        label: '備註',
        placeholder: '如您有其他需求，請輸入'
      },
      addService: '附加需求',
      exclusiveService: {
        label: '專享服務',
        placeholder: ''
      },
      detailService: {
        label: '具體要求',
        placeholder: '請輸入，如預訂的時間、地點、特殊要求等'
      }
    }
  },
  carIntroduction: {
    maximumPassengers: '最多搭乘',
    maximumPassengersLabel: '乘客',
    maximumPassengersUnit: '人',
    highestPlacement: '最高放置',
    highestPlacementLabel: '行李',
    highestPlacementUnit: '件',
    lowestPrice: '價格低至',
    otherBenefits: '其他福利',
    swiper1: '專業客制行程規劃',
    swiper2: '賓至如歸尊榮禮遇',
    swiper3: '資深團隊全程安心',
    swiper4: '定期健檢安全舒適'
  },
  carService: {
    title: '用車服務',
    swiper1: {
      label: '機場接送',
      desc: '出機口接機牌接機服務，\n' + '無須擔心航班延誤'
    },
    swiper2: {
      label: '商務用車',
      desc: '商務車型，專業司機，\n' + '車上提供免費WI-FI，出行、工作兩不誤'
    },
    swiper3: {
      label: '觀光用車',
      desc:
        '日本全境觀光用車，讓旅行高效、舒適、便利，不必擔心海外旅行的語言不通、行李不便、路線不熟等問題'
    },
    swiper4: {
      label: '高爾夫接送',
      desc:
        '出發地接至高爾夫球場，打球期間司機在外等候，\n' +
        '不必擔心球場遠，球包重，讓您輕享高爾夫之行 '
    },
    swiper5: {
      label: '其他附加服務',
      desc: '提供行程定制、訂酒店、訂餐廳、訂球場、訂門票等服務'
    },
    img1: {
      label: '高端車型',
      desc: '公司車輛皆為高端車型，定期檢修，對車輛狀態嚴格管理，更新淘汰，以保障用車安全、舒適。 '
    },
    img2: {
      label: '豪華座艙',
      desc: '車輛座艙皆為豪華座艙，高端座椅，讓您即使長途用車也不會過於勞累，在座艙舒適休息。'
    },
    img3: {
      label: '幹淨舒適',
      desc: '我們實施一客一清的方式，保證每位元客人用車前車輛都經過徹底清掃消毒。'
    },
    img4: {
      label: '金牌司機',
      desc: '司機皆經過專業且嚴格的培訓，服務讓您安心、貼心、放心。'
    },
    chooseUs: {
      title: '爲什麽選擇我們？',
      text1: '全部車型配備免費Wi-Fi',
      text2: '專業培訓 服務至上',
      text3: '安心、安全、舒適',
      text4: '不必擔心延誤等突發狀況',
      text5: '抵達大廳享受迎接服務'
    }
  },
  addService: {
    swiper1: {
      title: '旅行私人訂製',
      desc:
        '個性化私人訂製行程，讓\n' +
        '您旅途無憂。專屬行程定\n' +
        '制師，細心溝通，耐心解\n' +
        '答，盡享隨心日本之旅。'
    },
    swiper2: {
      title: '酒店預訂',
      desc:
        '提前預訂入住酒店及房\n' +
        '型，確保出行住宿無憂。\n' +
        '根據您的喜好提供酒店建\n' +
        '議，給您最好的入住體\n' +
        '驗。'
    },
    swiper3: {
      title: '餐厅預訂',
      desc:
        '預訂日本米其林、日料等\n' +
        '美食餐位，餐位無憂，讓\n' +
        '您用車出行同時享受地道\n' +
        '美食，尊貴用餐體驗。'
    },
    swiper4: {
      title: '高爾夫球預訂',
      desc:
        '提前規劃高爾夫球場使用\n' +
        '時間，享受專業球場設施\n' +
        '與服務暢享高爾夫運動樂\n' +
        '趣。'
    },
    swiper5: {
      title: '各種門票預訂',
      desc:
        '一站式服務，涵蓋各類活\n' +
        '動門票預訂，輕鬆獲取心\n' +
        '儀活動的入場資格，享受\n' +
        '便捷、安全的購票體驗！'
    },
    info: '更多服務需求，歡迎聯系我們！'
  },
  clubsSummary: {
    info: {
      title: '株式會社DD專車',
      desc:
        '具有十年以上行業經驗的東京專業專車公司，\n' +
        '以“服務至上，賓至如歸“為公司理念，為您提供專業、高端、安全、細緻入微的服務體驗。\n' +
        '日本專業國際化專車公司，公司有多國從業人員，提供日語、英語、中文、韓語等語言對應，海外客人也可安心享受。'
    },
    summary: {
      title: '公司概要',
      headQuarters: {
        label: '本社',
        content: '株式会社DDツーリズム'
      },
      representative: {
        label: '代表者',
        content: '望月 輝石'
      },
      headOffice: {
        label: '本社住所',
        content:
          '〒101-0021東京都千代田区外神田3丁目5番5号\n' +
          'TEL：03-6262-9696\n' +
          'FAX：03-6262-9630'
      },
      permitApprovalNumber: {
        label: '許可認可番号',
        content: '東京都知事登録旅行業2-8357'
      },
      managementCompany: {
        label: '運営会社',
        content: '株式会社DDハイヤー'
      },
      representative2: {
        label: '代表者',
        content: '淺井 漢鷹'
      },
      salesOffice: {
        label: '営業所',
        content:
          '〒133-0055東京都江戸川区西篠崎2丁目19番9号\n' +
          'TEL：03-5664-6122\n' +
          'FAX：03-5664-6132'
      },
      permitApprovalNumber2: {
        label: '許可認可番号',
        content: '関自旅ニ第762号'
      }
    },
    address: {
      title: '公司地址',
      info1: {
        name: '本社\n' + '株式会社DDツーリズム',
        detail: '東京都千代田区外神田3丁目5番5号',
        tel: 'TEL：03-6262-9696'
      },
      info2: {
        name: '営業所\n' + '株式会社DDハイヤー',
        detail: '東京都江戸川区西篠崎2丁目19番9号',
        tel: 'TEL：03-5664-6122'
      }
    },
    service: {
      title: '用車服務',
      line1: {
        swiper1: {
          title: '機場接送',
          desc: '出機口接機牌接機服務，無須擔心航班延誤。'
        },
        swiper2: {
          title: '商務用車',
          desc: '商務車型，專業司機，車上提供免費WI-FI，出行、工作兩不誤。'
        },
        swiper3: {
          title: '觀光用車',
          desc:
            '日本全境觀光用車，讓旅' +
            '行高效、舒適、便利，不' +
            '必擔心海外旅行的語言不' +
            '通、行李不便、路線不熟' +
            '等問題。'
        },
        swiper4: {
          title: '高爾夫接送',
          desc:
            '出發地接至高爾夫球場，' +
            '打球期間司機在外等候，' +
            '不必擔心球場遠，球包' +
            '重，讓您輕享高爾夫之行。'
        },
        swiper5: {
          title: '其他附加服務',
          desc: '提供行程定制、訂酒店、' + '訂餐廳、訂球場、訂門票' + '等服務。'
        }
      },
      line2: {
        swiper1: {
          title: '旅行私人訂制',
          desc:
            '個性化私人訂製行程，讓' +
            '您旅途無憂。專屬行程定' +
            '制師，細心溝通，耐心解' +
            '答，盡享隨心日本之旅。'
        },
        swiper2: {
          title: '酒店预訂',
          desc:
            '提前預訂入住酒店及房' +
            '型，確保出行住宿無憂。' +
            '根據您的喜好提供酒店建' +
            '議，給您最好的入住體' +
            '驗。'
        },
        swiper3: {
          title: '餐厅预訂',
          desc:
            '預訂日本米其林、日料等' +
            '美食餐位，餐位無憂，讓' +
            '您用車出行同時享受地道' +
            '美食，尊貴用餐體驗。'
        },
        swiper4: {
          title: '高爾夫球預訂',
          desc:
            '提前規劃高爾夫球場使用' + '時間，享受專業球場設施' + '與服務暢享高爾夫運動樂' + '趣。'
        },
        swiper5: {
          title: '各種門票預訂',
          desc:
            '一站式服務，涵蓋各類活' +
            '動門票預訂，輕鬆獲取心' +
            '儀活動的入場資格，享受' +
            '便捷、安全的購票體驗！'
        }
      },
      title2: '專享服務'
    }
  },
  pushDetails: {
    backPage: '回到首頁',
    recentPush: '近期推送',
    previous: '上壹頁',
    next: '下壹頁'
  },
  contactUs: {
    JoinUs: '加入我們',
    commonQA: '常見Q&A'
  },
  footer: {
    address: '東京都江戶川區西篠崎2-19-9 Excel9  911',
    tel: '03 - 5664 - 6122',
    email: 'dd.hire0214@gmail.com',
    fax: '03 - 5664 - 6132',
    tourismCompanyAddress: '日本東京都千代田區外神田3-5-5 末廣町HAIM大廈 8層',
    tourismCompanyTel: '03 - 6262 - 9696',
    tourismCompanyEmail: 'dd.tourism0220@gmail.com',
    tourismCompanyFax: '03 - 6262 - 9630'
  }
}
